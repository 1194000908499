<template>
  <div>
    <ui-section-header :showEdit="true" :editFunction="setShowEditSpaceLinks">
      <template v-slot:title>Links to</template>
    </ui-section-header>

    <article v-if="links.length === 0" class="message is-info">
      <div class="message-body">No linked spaces active</div>
    </article>

    <table v-if="links.length > 0" class="table is-fullwidth is-striped">
      <thead>
        <tr>
          <th>Linked space</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="link in links" :key="link.Id">
          <td>{{ link.LinkToName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      default: function() {
        return []
      },
      type: Array,
    },
  },

  data() {
    return {
      showEditSpaceLinks: false,
    }
  },

  methods: {
    setShowEditSpaceLinks() {
      this.showEditSpaceLinks = true
    },

    hideModal() {
      this.showEditSpaceLinks = false
    },
  },
}
</script>
